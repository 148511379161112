import Link from 'next/link'
import React from 'react'

// pages/404.js
export default function Custom404() {
    return (
        <React.Fragment>
            <style jsx>{`
                html,
                body {
                    height: 100%;
                }

                body {
                    position: relative;
                    min-width: 320px;
                    margin: 0;
                    padding: 0;
                    background-color: #edeff4;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                header {
                    z-index: 10;
                    position: relative;
                    padding: 12px 24px 11px;
                    box-sizing: border-box;
                    background-color: white;
                    border-bottom: 1px solid #d8dee4;
                }

                header:after {
                    content: '';
                    display: table;
                    clear: both;
                }

                header svg.logo {
                    float: left;
                    width: 84px;
                    height: 36px;
                }

                header svg .signal,
                header svg .wordmark,
                header svg circle {
                    fill: #2586d8;
                }

                header svg .marker path {
                    fill: #ff901f;
                }

                section.content {
                    position: relative;
                    z-index: 100;
                    padding: 8% 0 0;
                }

                section.content .string {
                    position: relative;
                    width: 420px;
                    height: 104px;
                    margin: 0 auto;
                }

                section.content .string .dot {
                    display: block;
                    z-index: 1;
                    width: 10px;
                    height: 10px;
                    margin: 0 auto;
                    background-color: white;
                    border-radius: 5px;
                    -webkit-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                }

                section.content .string .line {
                    position: absolute;
                    z-index: -1;
                    display: block;
                    height: 212px;
                    width: 3px;
                    background-color: #a0abb4;
                    -webkit-box-shadow: -4px 0px 3px 0px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: -4px 0px 3px 0px rgba(0, 0, 0, 0.1);
                    box-shadow: -4px 0px 3px 0px rgba(0, 0, 0, 0.1);
                }

                section.content .string .line.left {
                    left: 50%;
                    margin-left: -22%;
                    transform: translateY(-25%) rotate(-122deg);
                }

                section.content .string .line.right {
                    right: 50%;
                    margin-right: -22%;
                    transform: translateY(-25%) rotate(-58deg);
                }

                section.content .sign {
                    position: relative;
                    width: 100%;
                    max-width: 420px;
                    margin: 0 auto;
                    padding: 30px 48px 48px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    background-color: #2586d8;
                    text-align: center;
                    -webkit-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
                }

                section.content .sign h1 {
                    margin: 0 0 11px;
                    padding: 0;
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 114px;
                    font-weight: bold;
                    color: white;
                    letter-spacing: 4px;
                    line-height: 114px;
                }

                section.content .sign h2 {
                    margin: 0;
                    padding: 0;
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 18px;
                    font-weight: normal;
                    color: white;
                    line-height: 30px;
                }

                section.content .sign a.btn {
                    display: inline-block;
                    background-color: #ff901f;
                    height: 36px;
                    line-height: 36px;
                    padding: 0 32px;
                    margin: 16px 0 0;
                    color: white;
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 36px;
                    border: 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    text-decoration: none;
                }

                section.content .sign .btn:hover {
                    background-color: #fd8628;
                }

                section.content .sign .btn:focus {
                    outline: 0;
                }

                section.content .sign .dot {
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: #1876c5;
                    border-radius: 5px;
                }

                section.content .sign .dot.top-left {
                    top: 14px;
                    left: 14px;
                }

                section.content .sign .dot.top-right {
                    top: 14px;
                    right: 14px;
                }

                section.content .sign .dot.bottom-left {
                    bottom: 14px;
                    left: 14px;
                }

                section.content .sign .dot.bottom-right {
                    bottom: 14px;
                    right: 14px;
                }

                @media only screen and (max-width: 768px) {
                    header {
                        position: relative;
                        z-index: 999;
                        margin-bottom: 0;
                        padding: 12px 16px;
                        width: 100%;
                    }

                    header svg.logo {
                        width: 74px;
                        height: 32px;
                    }

                    header h1 {
                        float: right;
                        margin: 6px 0;
                        line-height: 14px;
                        font-size: 14px;
                    }
                }

                @media only screen and (max-width: 620px) {
                    section.content {
                        padding: 20px;
                    }

                    section.content .sign {
                        padding: 30px 32px 48px;
                    }

                    section.content .sign h1 {
                        font-size: 94px;
                        line-height: 100px;
                    }

                    section.content .string {
                        display: none;
                    }
                }
            `}</style>
            <section className="content">
                <div className="string">
                    <span className="dot"></span>
                    <span className="line left"></span>
                    <span className="line right"></span>
                </div>
                <div className="sign">
                    <span className="dot top-left"></span>
                    <span className="dot top-right"></span>
                    <span className="dot bottom-left"></span>
                    <span className="dot bottom-right"></span>
                    <h1>404</h1>
                    <h2>
                        Oops... The page you were searching for could not be
                        found
                    </h2>
                    <Link href="/">Go to homepage</Link>
                </div>
            </section>
        </React.Fragment>
    )
}
